import styled, { css } from 'styled-components';

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';

const Layout = styled.div`
  width: 100%;
  padding: 14px 28px 32px 21px;
  
  @media (max-width: 500px) {
    padding: 14px 10px 32px;
  } 
`;

const CardDashboard = styled.div`
  background-color: #ededed;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CardContainer = styled.div`
  width: calc((100%/4) - 18px);
  margin: 18px 9px 0;
  padding: 0px 10px;
  min-height: 120px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0,0,0,0.08);
  display: inline-block;
  vertical-align: top;
  color: #6F767E;
  position: relative;
  
  @media (max-width: 1225px) {
    width: calc((100%/2) - 18px);
  }
  
  @media (max-width: 580px) {
    width: 100%;
    margin: 18px 0 0;
  }
`;

const CardTitle = styled.h6`
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  color: #6F767E;
  font-weight: 700;
  min-height: 30px;
`;

const CardItems = styled.div`
  display: flex;
  align-items: center;
  margin: 18px 0 0 0;
  float: left;
  width: 100%;
`;

const CardStats = styled.div`
  flex-basis: 50%;
`;

const CardKey = styled.p`
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  font-family: ${BoldFont};
  color: #9EA0A5;
`;

const CardValue = styled.p`
  font-size: 35px;
  color: ${({smallFont, opacity}) => smallFont && opacity ? 'rgba(40,44,55, 1)' : smallFont ? 'rgba(40,44,55, 0.5)' :'#3e4e68'};
  line-height: 1;
  margin: 0;
  font-family: ${BoldFont};
  ${({smallFont}) => smallFont && css`
    font-size: 18px;
    font-family: ${MediumFont};
 `}
 ${({smallFont, opacity}) => smallFont && opacity && css`
    font-family: ${BoldFont};
 `}
`;

const CardImageContainer = styled.div`
  flex-basis: 50%;
  text-align: right;
  
  img {
    width: 75px;
    height: 40px;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 365px;
`;

const Section = styled.div`
  display: block;
  width: ${({sectionLength}) => sectionLength ? `calc((100%/${sectionLength}) - 18px)` : `calc(50% - 18px)`};
  flex-grow: 1;
  margin: 29px 9px 0;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  ${({isBigSection}) => isBigSection && css`
    width: calc(50% - 18px);
  `}

  ${({isSmallSection}) => isSmallSection && css`
    width: calc(50%/2 - 18px);
  `}
  
  @media (max-width: 1200px) {
    flex-grow: 1
    flex-shrink: 0;
    flex-basis: 48%
  }  
  @media (max-width:750px) {
    width: calc(100%/2 - 18px)
  }
`;

const SectionHeader = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #FFFFFF;
  background: #1C4667;
  border: 0 solid rgba(184,184,196,0.52);
  padding: 10px 17px;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  font-family: ${MediumFont};
  letter-spacing: 0.8px;
`;

const GraphContainer = styled.div`
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  padding: ${({padding}) => padding ? padding : '30px 20px'};
`;
const DropDownWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding: 7px 5px 0px;
   .dropdown-menu {
    min-width: 54px;
    width: 67px;
    > li {
        > a {
          padding: 5px 15px;
        }
      }
    > ul {
      max-width: 300px;
    }
  }
`;
export { Layout, CardContainer, CardTitle, CardItems, CardKey, CardValue, CardImageContainer, CardStats, 
  CardDashboard, SectionContainer, Section, SectionHeader, GraphContainer, DropDownWrapper};