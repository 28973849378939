import React from "react";
import PropTypes from "prop-types"; 
import { CardContainer, CardKey, CardValue, CardImageContainer, CardStats,CardDashboard } from "./styles";
import {ArrowContainer, Row1, Row2, Row3} from '../HealthTabV2/styles';
import {TotalCount} from "../CompanyDashBoardV2/styles";
import {isNull, isUndefined} from "lodash";
import {calculateChangeInData} from "../../utils/methods";

const CompanyDetails = ({companyDetails}) => (
  <CardDashboard>
    {
      companyDetails.map((item) => {
        let isTrendIndicator = calculateChangeInData(item.value, item.lastYear);
        return <CardContainer key={item.key}>
          <Row1>
            <p>{item.title}</p>
            {isTrendIndicator.trendIndicator !== 0 ? <ArrowContainer transform={item.keyFactor * isTrendIndicator.trendIndicator}>
              <img src={"/images/CompanyDashBoardV2/Health/arrow-up.svg"} alt={'arrow-up'}/>
            </ArrowContainer> : null}
          </Row1>
          <Row2>
            <CardStats>
              <CardKey>{item.key}</CardKey>
              <TotalCount>{isNull(item.value) || isUndefined(item.value) ? 'NA' : `${item.value}%`}</TotalCount>
            </CardStats>
            <CardImageContainer>
              <img src={`/images/CompanyDashBoardV2/${(item.keyFactor * isTrendIndicator.trendIndicator) === 0 ? 'Health/neutral-indicator.svg' : (item.keyFactor * isTrendIndicator.trendIndicator) === 1 ? 'Workplace/graph1.svg' : 'Health/graph3.svg'}`} alt={'graph'}/>
            </CardImageContainer>
          </Row2>
          <Row3>
            <CardStats>
              <CardValue smallFont={1}>{isNull(item.lastYear) || isUndefined(item.lastYear) ? 'NA' : `LY ${item.lastYear}%`}</CardValue>
            </CardStats>
            <CardImageContainer>
              <CardValue smallFont={1} opacity={1}>{isTrendIndicator.value}</CardValue>
            </CardImageContainer>
          </Row3>
        </CardContainer>
      })
    }
  </CardDashboard>
);

CompanyDetails.propTypes = {
  companyDetails: PropTypes.array
};

export default CompanyDetails;