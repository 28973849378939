import React, {Component} from 'react';
import PropTypes from "prop-types";
import {BottomLabelDonutContainer, NoData} from "../BiometricTab/styles";
import DonutChart from "../DonutChartV2";

const payload= [
  {
    name: 'Daily',
    color: '#FF3F19'
  },
  {
    name: 'Weekly',
    color: '#FBAE19'
  },
  {
    name: 'Monthly',
    color: '#0C99C1'
  },
  {
    name: 'Rarely/Never',
    color: '#8FBA3C'
  }
];


export default class ProcessedFood extends Component{
  constructor(){
    super();
    this.state = {
      options: ["2019","2018","2017"],
      selectedIndex: 0,
      selectedValue: 2019
    }
  }

  renderDonutChart = (chartData, container) =>{
    const total = chartData.reduce((acc, item) => (acc + item.y
    ), 0)
    return total  === 0 ? <NoData>No Glucose Data</NoData> : <DonutChart data={chartData} container={container} legendWidth={62}/>
  };

  render() {
    const {container, chartData} = this.props;
    return(
      <React.Fragment>
        {this.renderDonutChart(chartData, container)}
        <BottomLabelDonutContainer container={'sugar-intake'}>
          {
            payload.map((item, index) => (
              <div key={index}>
                <span style={{backgroundColor: item.color}}/>
                <div>{item.name.split('/')[0]}<br/>{item.name.split('/')[1]}</div>
              </div>
            ))
          }
        </BottomLabelDonutContainer>
      </React.Fragment>
    )
  }
}

ProcessedFood.propTypes = {
  chartData: PropTypes.array,
  container: PropTypes.string
};

