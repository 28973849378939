/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout, SectionContainer, Section, SectionHeader, GraphContainer, DropDownWrapper } from "./styles";
import CompanyDetails from "./companyDetails";
import VerticalBarChart from '../VerticalBarChart';
import HorizontalBarGraph from "../HorizontalBarGraph";
import ProcessedFood from './ProcessedFood';
import {connect} from "react-redux";
import {FitnessCommonContainer} from "../FitnessTabV2/styles";
import _ from "lodash";
import isEmpty from 'lodash/isEmpty';
import Waiting from '../Waiting';
import {NoData} from "../BiometricTab/styles";
const SECTIONS = {
  cause_section: [
    {headerTitle: "What hurdles might you have towards eating healthy? Please select all that apply."},
    {headerTitle: "What causes you the most stress?"},
  ],
  eat_section: [
    {headerTitle: "How often do you eat fresh fruit and vegetables?"},
    {headerTitle: "How often do you eat or drink foods with Added Sugars?"},
    {headerTitle: "How often do you stress about your finances?"},
  ],
  survey_section: [
    {
      headerTitle: "How often do you take 20 or more minutes to do something that relaxes you? (eg. “stop and smell the roses”, meditate, read, exercise)",
      isBigSection: true
    },
    {
      headerTitle: "How often do you eat highly processed foods?",
      isSmallSection: true
    },
    {
      headerTitle: "How often do you make conscious decisions about eating healthy?",
      isSmallSection: true
    },
  ]
}

const xAxisLabel = ["Budget", "Stress", "Convenience", "Cravings", "Don't Know What's Healthy", "I Enjoy Eating Healthy", "Culture / Family"];
const xAxisLabel1 = ["Money", 'Family', 'Relationship', 'Friends', 'Chronic Pain', 'Work', 'Self Confidence']
import {fetchWellbeingAPI} from '../../redux/actions';
class Wellbeing extends Component {

  componentDidMount(){
    const{selectedYearInfo, fetchWellbeingAPI, companyId} = this.props;
    fetchWellbeingAPI(companyId, selectedYearInfo);
  }
  componentDidUpdate(prevProps){
    const{selectedYearInfo, fetchWellbeingAPI, companyId} = this.props;
    if( prevProps.selectedYearInfo !== selectedYearInfo || prevProps.companyId !== companyId){
      fetchWellbeingAPI(companyId, selectedYearInfo);
    }
  }
  getHeaderData = (headerData, lastYearData, maxStressFactor, maxBiggestHurdles) => (
     [
      {
        title: "causes the most stress",
        key: maxStressFactor[0].split('_')[0],
        value: headerData[0],
        lastYear: lastYearData[0],
        image: "/images/CompanyDashBoardV2/stress.png",
        description: "Small description about the total member",
        keyFactor: -1
      },
      {
        title: "biggest hurdle to eat healthy",
        key: maxBiggestHurdles[0].split('_')[0],
        value: headerData[1],
        lastYear: lastYearData[1],
        image: "/images/CompanyDashBoardV2/total-company-graph.png",
        description: "Small description about the total member",
        keyFactor: -1
      },
      {
        title: "stressing about finances",
        key: "monthly",
        value: headerData[2],
        lastYear: lastYearData[2],
        image: "/images/CompanyDashBoardV2/monthly-member-graph.png",
        description: "Small description about the total member",
        keyFactor: -1
      },
      {
        title: "conscious about health eating",
        key: "weekly",
        value: headerData[3],
        lastYear: lastYearData[3],
        image: "/images/CompanyDashBoardV2/Health/graph3.svg",
        description: "Small description about the total member",
        keyFactor: 1
      },
    ]
  )

  getSeriesDataForHurdlesInEating = (graphData) => {
    if(_.isUndefined(graphData) || _.isNull(graphData) || isEmpty(graphData)) {
      return(
        <div>
            <FitnessCommonContainer textAlign>
            {
              'No Data For Hurdles in Eating'
            }
          </FitnessCommonContainer>
        </div>
      );
    }
    return [
      {
        color: "#0C99C1",
        data: [graphData['budget_percentage'], graphData['stress_percentage'], graphData['convenience_percentage'], graphData['cravings_percentage'], graphData['dont_know_whats_healthy_for_me_percentage'], graphData['Other Percentage'], graphData['culture_or_family_percentage']],
        dataLabels: {color: "#0C99C1"},
        name: "",
        showInLegend: false,
        stack: 0,
      }
    ]
  };

  getSeriesDataForStressCausing = (graphData) => {
    if(_.isUndefined(graphData) || _.isNull(graphData) || isEmpty(graphData)) {
      return(
        <div>
          <FitnessCommonContainer textAlign>
            {
              'No Data For Stress Causing Factors'
            }
          </FitnessCommonContainer>
        </div>
      );
    }

    return [
      {
        color: "#0C99C1",
        data: [graphData['money_percentage'], graphData['family_percentage'], graphData['relationship_percentage'], graphData['friends_percentage'], graphData['Chronic pain Percentage'], graphData['work_percentage'], graphData['self_confidence_percentage']],
        dataLabels: {color: "#0C99C1"},
        name: "",
        showInLegend: false,
        stack: 0,
      }
    ]
  };

  getFrequencyOfBarGraphData = (graphData, noData) => {
    if(_.isUndefined(graphData) || _.isNull(graphData) || isEmpty(graphData)) {
      return(
        <div>
          <FitnessCommonContainer textAlign>
            {
              noData
            }
          </FitnessCommonContainer>
        </div>
      );
    }
    return  [
      {name: "Daily", value: `${graphData['daily_percentage']}%`, width: `${graphData['daily_percentage']}`, backgroundColor: "#FF3F19", backgroundFill: "rgba(255,63,25,0.2)"},
      {name: "Few times/week", value: `${graphData['few_times_per_week_percentage']}%`, width: `${graphData['few_times_per_week_percentage']}`, backgroundColor: "#FBAE19", backgroundFill: "rgba(251,174,25,0.2)"},
      {name: "Few times/month", value: `${graphData['few_times_per_month_percentage']}%`, width: `${graphData['few_times_per_month_percentage']}`, backgroundColor: "#64CCEC", backgroundFill: "rgba(100,204,236,0.2)"},
      {name: "Rarely or never", value: `${graphData['rarely_or_never_percentage']}%`, width: `${graphData['rarely_or_never_percentage']}`, backgroundColor: "#94BF3E", backgroundFill: "rgba(148,191,62,0.2)"},
    ];
  };

  getRelaxingData = (graphData) => {
    return  [
      {name: "Daily", value: `${graphData['daily_percentage']}%`, width: `${graphData['daily_percentage']}`, backgroundColor: "#0C99C1", backgroundFill: "rgb(12,153,193,0.2)"},
      {name: "Few times/week", value: `${graphData['few_times_per_week_percentage']}%`, width: `${graphData['few_times_per_week_percentage']}`, backgroundColor: "#0C99C1", backgroundFill: "rgba(12,153,193,0.2)"},
      {name: "Few times/month", value: `${graphData['few_times_per_month_percentage']}%`, width: `${graphData['few_times_per_month_percentage']}`, backgroundColor: "#0C99C1", backgroundFill: "rgba(12,153,193,0.2)"},
      {name: "Rarely or never", value: `${graphData['rarely_or_never_percentage']}%`, width: `${graphData['rarely_or_never_percentage']}`, backgroundColor: "#0C99C1", backgroundFill: "rgba(12,153,193,0.2)"},
    ];
  }

  getFrequencyOfEating = (graphData, noData) => {
    if(_.isUndefined(graphData) || _.isNull(graphData) || isEmpty(graphData)) {
      return(
        <div>
          <FitnessCommonContainer textAlign>
            {
              noData

            }
          </FitnessCommonContainer>
        </div>
      );
    }
    return [
      {
        name: 'Daily',
        color: '#FF3F19',
        y: graphData['daily_percentage']
      },
      {
        name: 'Weekly',
        color: '#FBAE19',
        y: graphData['few_times_per_week_percentage']
      },
      {
        name: 'Monthly',
        color: '#0C99C1',
        y: graphData['few_times_per_month_percentage']
      },
      {
        name: 'Rarely/Never',
        color: '#8FBA3C',
        y: graphData['rarely_or_never_percentage']
      },

    ];
  };
  render() {
    const {hurdleInEatingData, stressCausingFactors, frequencyOfEatingFreshFruits, frequencyOfEatingDrinkingAddedSugar, frequencyOfStressAboutYourFinances,frequencyOfRelaxingDetails, frequencyOfHighlyProcessedFoodDetails, frequencyOfEatingHealthyDetails, showReportTab, selectedYearInfo, yearList, wellbeingLoading, reportMessage} = this.props;
    if(!showReportTab){
      return(
        <React.Fragment>
          <NoData>
            {reportMessage ? `${reportMessage}` : null}<br />
            Minimum 20 users should have completed the HRA to view the aggregate health reports
          </NoData>
        </React.Fragment>
      );
    }
    if(_.isNull(frequencyOfEatingFreshFruits) || _.isUndefined(frequencyOfEatingFreshFruits) || isEmpty(frequencyOfEatingFreshFruits) ||
      _.isNull(frequencyOfEatingDrinkingAddedSugar) || _.isUndefined(frequencyOfEatingDrinkingAddedSugar) || isEmpty(frequencyOfEatingDrinkingAddedSugar) ||
      _.isNull(frequencyOfStressAboutYourFinances) || _.isUndefined(frequencyOfStressAboutYourFinances) || isEmpty(frequencyOfStressAboutYourFinances) ||
      _.isNull(frequencyOfRelaxingDetails) || _.isUndefined(frequencyOfRelaxingDetails) || isEmpty(frequencyOfRelaxingDetails) ||
      _.isNull(frequencyOfHighlyProcessedFoodDetails) || _.isUndefined(frequencyOfHighlyProcessedFoodDetails) || isEmpty(frequencyOfHighlyProcessedFoodDetails) ||
      _.isNull(frequencyOfEatingHealthyDetails) || _.isUndefined(frequencyOfEatingHealthyDetails) || isEmpty(frequencyOfEatingHealthyDetails) ||
      _.isNull(stressCausingFactors) || _.isUndefined(stressCausingFactors) || isEmpty(stressCausingFactors) ||
      _.isNull(hurdleInEatingData) || _.isUndefined(hurdleInEatingData) || isEmpty(hurdleInEatingData ) || wellbeingLoading
    ){
      return <Waiting />
    }
    const averageHeaderData = [stressCausingFactors["average_percentage"], hurdleInEatingData["average_percentage"],
      frequencyOfStressAboutYourFinances["average_percentage"], frequencyOfEatingHealthyDetails["average_percentage"]];
    const lastYearAverageData = [stressCausingFactors["last_year_average_percentage"], hurdleInEatingData["last_year_average_percentage"],
      frequencyOfStressAboutYourFinances["last_year_average_percentage"], frequencyOfEatingHealthyDetails["last_year_average_percentage"]]
    const getMaxStressCausingFactor = Object.keys(stressCausingFactors).filter((item) => {
      return item !== 'average_percentage' && stressCausingFactors[item] === stressCausingFactors['average_percentage']
    });
    const getMaxBiggestHurdles = Object.keys(hurdleInEatingData).filter((item) => {
      return item !== 'average_percentage' && hurdleInEatingData[item] === hurdleInEatingData['average_percentage']
    });
    return (
      <Layout>
        <CompanyDetails companyDetails={this.getHeaderData(averageHeaderData, lastYearAverageData, getMaxStressCausingFactor, getMaxBiggestHurdles)} />
        <SectionContainer>
          {
            SECTIONS.cause_section.map((item, index) => (
              <Section 
                key={item.headerTitle} 
                sectionLength={SECTIONS.cause_section.length} 
              >
                <SectionHeader>{item.headerTitle}</SectionHeader>
                <GraphContainer>
                  <VerticalBarChart
                    id={`chart-${index}`}
                    seriesData={index==0 ? this.getSeriesDataForHurdlesInEating(hurdleInEatingData) : this.getSeriesDataForStressCausing(stressCausingFactors)}
                    xAxisLabel={index ==0 ? xAxisLabel : xAxisLabel1}
                    yAxisVisible={false}
                    fontWeight={700}
                    wellBeing={1}
                  />
                </GraphContainer>
              </Section>
            ))
          }
        </SectionContainer>
        <SectionContainer>
          {
            SECTIONS.eat_section.map((item, index) => (
              <Section 
                key={item.headerTitle} 
                sectionLength={SECTIONS.eat_section.length} 
              >
                <SectionHeader>{item.headerTitle}</SectionHeader>
                <GraphContainer>
                  <HorizontalBarGraph
                    data={index == 0 ? this.getFrequencyOfBarGraphData(frequencyOfEatingFreshFruits, "No Data For Eating Fresh Food") : index==1 ? this.getFrequencyOfBarGraphData(frequencyOfEatingDrinkingAddedSugar, 'No Data For Eating and Drinking Added Sugar') : this.getFrequencyOfBarGraphData(frequencyOfStressAboutYourFinances, 'No Data For Stress In Finances')}
                  />
                </GraphContainer>
              </Section>
            ))
          }
        </SectionContainer>
        <SectionContainer>
          {
            SECTIONS.survey_section.map((element, itemIndex) => (
              <Section 
                key={itemIndex} 
                sectionLength={3}
                isBigSection={element.isBigSection} 
                isSmallSection={element.isSmallSection}

              >
                <SectionHeader>{element.headerTitle}</SectionHeader>
                {
                  itemIndex === 0 ?
                    <GraphContainer>
                      <HorizontalBarGraph
                        data={this.getRelaxingData(frequencyOfRelaxingDetails)}
                      />
                    </GraphContainer>
                     : <ProcessedFood
                    container={`donut-chart-${itemIndex}`}
                    chartData={itemIndex === 1 ? this.getFrequencyOfEating(frequencyOfHighlyProcessedFoodDetails, 'No Data For Processed Food') : this.getFrequencyOfEating(frequencyOfEatingHealthyDetails, 'No Data For Eating Healthy')}
                  />
                }
              </Section>
            ))
          }
        </SectionContainer>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  hurdleInEatingData: state.companyDashboard.hurdleInEatingData,
  stressCausingFactors: state.companyDashboard.stressCausingFactors,
  frequencyOfEatingFreshFruits: state.companyDashboard.frequencyOfEatingFreshFruits,
  frequencyOfEatingDrinkingAddedSugar: state.companyDashboard.frequencyOfEatingDrinkingAddedSugar,
  frequencyOfStressAboutYourFinances: state.companyDashboard.frequencyOfStressAboutYourFinances,
  frequencyOfRelaxingDetails: state.companyDashboard.frequencyOfRelaxingDetails,
  frequencyOfHighlyProcessedFoodDetails: state.companyDashboard.frequencyOfHighlyProcessedFoodDetails,
  frequencyOfEatingHealthyDetails: state.companyDashboard.frequencyOfEatingHealthyDetails,
  showReportTab: state.companyDashboard.showReportTab,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  wellbeingLoading: state.companyDashboard.wellbeingLoading,
  reportMessage: state.companyDashboard.reportMessage,
});

Wellbeing.propTypes = {
  companies: PropTypes.array,
  showReportTab: PropTypes.bool,
  selectedYearInfo: PropTypes.number,
  wellbeingLoading: PropTypes.bool,
  fetchWellbeingAPI: PropTypes.func,
  companyId: PropTypes. number,
  reportMessage: PropTypes.string
};
const mapDispatchToProps = (dispatch) => ({
  fetchWellbeingAPI: (companyId, year) => dispatch(fetchWellbeingAPI(companyId, year))
})
export default connect(mapStateToProps, mapDispatchToProps)(Wellbeing);